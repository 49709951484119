<script>
import { ColorPanel } from 'one-colorpicker';
export default{
  components:{
    'color-panel': ColorPanel,
  },
  props:{
    title: {
      type:String,
      required:true
    },
   value:{
      type:String,
      required: true
   }
  },
  data(){
    return {

    }
  },
  computed:{
    uuid(){
      return this.string_to_slug(this.title) + '-' + this.create_UUID();
    },
    color: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods:{
    string_to_slug (str) {
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to   = "aaaaeeeeiiiioooouuuunc------";
      for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes

      return str;
    },
    create_UUID(){
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c==='x' ? r :(r&0x3|0x8)).toString(16);
      });
      return uuid;
    }
  },

}
</script>

<template>
  <div class="d-flex">
    <button :id="uuid" class="tooltip-button"><span class="tooltip-color" :style="'background:' + color"></span></button>
    <b-tooltip :target="uuid" triggers="click">
      <span class="tooltip-header">
        <span class="float-left mb-2">{{title}}</span>
        <button class="float-right tooltip-close-button" @click="$root.$emit('bv::hide::tooltip')">Close</button>
      </span>
      <color-panel v-model="color" />
    </b-tooltip>
  </div>
</template>

<style lang="scss">
.tooltip.b-tooltip{
  opacity: 1;
  .tooltip-inner{
    background: #000;
  }
}
.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before{
  border-bottom-color: #000;
}
.tooltip-button{
  appearance: none;
  background: none;
  border: 0;
  .tooltip-color{
    border-radius: 50%;
    border: 2px solid #fff;
    width: 25px;
    height: 25px;
    display: block;
  }
}
.tooltip-close-button{
  appearance: none;
  background: none;
  border: 0;
  color: #fff;
  padding: 0;
}
.one-color-stash{
  display: none !important;
}
</style>
